<template>
  <div class="col-span-1">
    <div
      class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative"
      >
      <!-- <span class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
      <div class="flex justify-between items-center mb-4">
        <div class="text-xs">{{ colName }}</div>
        <div v-if="isNaira" class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black">
          ₦
        </div>
        <div v-else-if="icon" class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
          <ion-icon :name="icon"></ion-icon>
        </div>
      </div>
      <div class="text-xs font-medium">
        {{ getColValue }}
      </div>
    </div>
</div>
</template>
<script>
export default {
  computed: {
  getColValue() {
    if (!this.colValue) {
      console.log(typeof this.colValue);
      return (typeof this.colValue === 'number' ? 0 : '---');
    }
    return this.isNaira ? '₦ ' + this.colValue : this.colValue;
  }
  },
  props: {
    colName: [String, Number],
    colValue: [String, Number],
    isNaira: Boolean,
    icon: String,
  }
}
</script>